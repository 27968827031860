import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import * as _ from 'lodash';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
 import * as $ from 'jquery';
 
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
     'Access-Control-Allow-Origin': '*',
  })
};

@Component({
  selector: 'app-loanform',
  templateUrl: './loanform.component.html',
  styleUrls: ['./loanform.component.css']
})

export class LoanformComponent implements OnInit {
    model: any = {};
    public years: any[];
    public form1: boolean;
    public form2: boolean;
    public form3: boolean;
    public days: any[];
    public states: any[];
    homemodel: any = {};
    submitted = false;
    
  constructor(
    private AppService: AppService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
      this.form1 = true;
    this.years = [];
      this.days = [];
    for(let i= 2000; i>= 1938; i--){
        this.years.push(i);
    }
    for(let i= 1; i<= 31; i++){
        this.days.push(i);
    }
    this.states = ['AL','AR','AZ','CA','CO','CT','DC','DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA','MD','ME','MI','MN','MO','MS','MT',
    'NC','ND','NE','NH','NJ','NM','NV','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VA','WA','WI','WY'];
    
    console.log('this.days', this.days);
//    apiId: "DC4C5C283CFB11E89AB842010A80057E",
//        apiPassword: "5474792a",
    this.model = {
        apiId: "FFF1EDB6D49211E8A0B842010A800486",
        apiPassword: "8e786e3e3",
        testMode: "1",
        productId: "1",
        price: "0",
        incomeNextDate1: "2018-06-05",
        incomeNextDate2: "2018-06-12",
        webSiteUrl: "lendyou.com",
        birth_day: "",
        state: "",
        license_state: "",
        empState: "",
        income_type: "Employed",
        credit_rating: "",
        time_to_call: "",
        addressLengthMonths: "",
        dob: "1980-05-26",
        bankDirectDeposit: "YES",
        incomePaymentFrequency: "",
        bankAccountType: "",
        bankAccountLengthMonths: "",
        incomeType: ""
    };
//    this.model['testSold'] = 1;
    this.AppService.getData((homemodel)=>{
        this.homemodel = homemodel;
        console.log('in loan comp',this.homemodel );
        if(_.isEmpty(this.homemodel)){
//            this.router.navigate(['']);
        }
        _.merge(this.model,this.homemodel);
        console.log('this.states', this.states.length);
        
        $.getJSON('http://gd.geobytes.com/GetCityDetails?callback=?', (data) => {
            if(data.geobytesremoteip){
                this.model.userIp = data.geobytesremoteip;
            } 
        });
        console.log('this.model', this.model);
    });
    
  }
  onSubmitFirstStep(){
      console.log('in submit 1');
      this.form1 = false;
      this.form2 = true;
      this.form3 = false;
  }
  onFrom2Submit(){
      console.log('in submit 2');
      this.form1 = false;
      this.form2 = false;
      this.form3 = true;
  }
  backFromStep3(){
      this.form2 = true;
      this.form3 = false;
  }
  backFromStep2(){
      this.form1 = true;
      this.form2 = false;
  }
  onSubmit() {
      this.model.dob = this.model.birth_year + '-' + this.model.birth_month + '-' + this.model.birth_day ;
      
//    this.http.get('https://leads-om41-client.phonexa.com/lead', {
//        params: this.model,
//        observe: 'response'
//    })
//    .toPromise()
//    .then(response => {
//        console.log('response',response);
//    })
//    .catch(error=> {
//        console.log('error', error);
//    });
    
      let self = this;
//     $.ajax({
//
//                    url: 'https://leads-om41-client.phonexa.com/lead',
//                    data: this.model,
//                    contentType: 'application/json; charset=utf-8',
//                    dataType: 'text json',
//                    cache: false,
//                    "method": "GET",
//                    "async": true,
//                    success: function(data) { 
//                        alert("Success"); 
//                        console.log('data', data);
//                    },
//                    error: function(error) { 
////                        alert('Failed!');
//                        console.log('error', error.then(function(){
//                            
//                        })); 
//                        self.router.navigate(['/offer']);
//                    }
//        });
     $.ajax({

                    url: 'https://leads-om41-client.phonexa.com/lead',
                    data: this.model,
                    "type": "GET",
                    success: function(data) { 
                        alert("Success"); 
                        console.log('data', data);
                    },
                    error: function(error) { 
                        console.log('error', error);
                        self.router.navigate(['/offer']);
                    }
        }).done(function (response) {
            console.log('response',response);
        });
//        var settings = {
//            "async": true,
//            "crossDomain": true,
//            "url": "https://leads-om41-client.phonexa.com/lead/?testMode=1&apiId=FFF1EDB6D49211E8A0B842010A800486&apiId=FFF1EDB6D49211E8A0B842010A800486&apiPassword=8e786e3e3&apiPassword=8e786e3e3&productId=1&productId=1&price=0&price=0&loanAmount=100&loanAmount=100&workCompanyName=walmart&workCompanyName=walmart&jobTitle=manager&jobTitle=manager&activeMilitary=NO&activeMilitary=NO&workTimeAtEmployer=36&workTimeAtEmployer=36&ssn=123333333&ssn=123333333&driversLicenseNumber=F3383884&driversLicenseNumber=F3383884&driversLicenseState=CA&driversLicenseState=CA&incomeType=EMPLOYMENT&incomeType=EMPLOYMENT&incomePaymentFrequency=WEEKLY&incomePaymentFrequency=WEEKLY&incomeNetMonthly=3000&incomeNetMonthly=3000&incomeNextDate1=2018-10-21&incomeNextDate1=2018-10-21&incomeNextDate2=2018-10-28&incomeNextDate2=2018-10-28&bankDirectDeposit=YES&bankDirectDeposit=YES&bankAba=111326071&bankAba=111326071&bankName=Chase&bankName=Chase&bankPhone=7273738283&bankPhone=7273738283&bankAccountNumber=123123123&bankAccountNumber=123123123&bankAccountType=CHECKING&bankAccountType=CHECKING&bankAccountLengthMonths=100&bankAccountLengthMonths=100&firstName=John&firstName=John&lastName=Smith&lastName=Smith&dob=1980-05-26&dob=1980-05-26&address=16262sugargrodedr&address=16262sugargrodedr&zip=90202&zip=90202&city=glendale&city=glendale&state=CA&state=CA&ownHome=NO&ownHome=NO&addressLengthMonths=50&addressLengthMonths=50&email=jahn.n%40yahoo.com&email=jahn.n%40yahoo.com&homePhone=5206574468&homePhone=5206574468&workPhone=5209133547&workPhone=5209133547&cellPhone=9294134578&cellPhone=9294134578&userIp=64.60.147.154&userIp=64.60.147.154&webSiteUrl=genericloansite.com%22%22https%3A%2F%2Fleads-om41-client.phonexa.com%2Flead%2F%3FtestMode%3D1&webSiteUrl=genericloansite.com",
//            "method": "GET",
//            "headers": {
//              "cache-control": "no-cache",
//               "Accept": "application/json; charset=UTF-8"
//            },
//            success: function(data) { 
//                alert("Success"); 
//                console.log('data', data);
//            },
//            error: function(error) { 
////                        alert('Failed!');
//                console.log('error', error); 
////                self.router.navigate(['/offer']);
//            }
//        }
//
//$.ajax(settings).done(function (response) {
//  console.log('response',response);
//});
//    this.http.post("https://leads-om41-client.phonexa.com/lead/", this.model, httpOptions)
//    .pipe(
//    ).subscribe(response => {
//        console.log('response', response);
//        
//    });
//    alert("XMLHttpRequest cannot load https://leads-om41-client.phonexa.com/lead/. Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource. Origin 'http://payday.wordinbox.in' is therefore not allowed access.");
    
  }

}
