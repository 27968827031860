import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    model: any = {};
    public years: any[];
    submitted = false;
    
    constructor(
      private AppService: AppService,
      private router: Router
    ) { }

  ngOnInit() {
      this.years = [];
      for(let i= 2000; i>= 1938; i--){
          this.years.push(i);
      }
      this.model = {
          loanAmount: 300,
          birth_year: 1980
      };
  }
   onSubmit() {
       this.AppService.saveData(this.model);
       console.log('data', this.model);
        this.router.navigate(['/loanform']);
  }

}
