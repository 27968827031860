import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoanformComponent } from './loanform/loanform.component';
import { OfferComponent } from './offer/offer.component';




const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'loanform', component: LoanformComponent },
  { path: 'offer', component: OfferComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingcompnent = [HomeComponent, LoanformComponent]
