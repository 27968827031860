import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('script executed');
    var _clcktt = _clcktt || {};
    _clcktt['cId'] = '951';
    document.write('<div id="clcktt"></div>');
    (function() {
        var ca = document.createElement('script'); ca.type = 'text/javascript'; ca.async = true;
        ca.src = 'https://revpie.com/js/extractor.js';
        var sc = document.getElementsByTagName('script')[0]; 
        sc.parentNode.insertBefore(ca, sc);
    })();
  }

}
