import { Injectable, Input, EventEmitter, Output } from '@angular/core';

@Injectable(
)

export class AppService {

  model = {};

  @Output() change: EventEmitter<object> = new EventEmitter();

  saveData(model) {
    console.log('in savedata', model);
    this.model = model;
    this.change.emit(this.model);
  }
  getData(callback){
      callback(this.model);
  }

}